<template>
  <div class="">
    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-7 easy-in-out">
      <div v-for="item in data" :key="item.id">
        <t-card :data="item" class="h-full">
          <template #description>
            <div class="hidden lg:block">
              {{ item.activity.snippet }}
            </div>
          </template>
          <template #footer>
            <div class="flex flex-row space-x-3">
              <div
                class="h-full border border-2 !rounded-lg"
                :style="{ 'border-color': item.activity.category.color }"
              />
              <div
                class="font-semibold text-gray-300 text-lg sf-pro-text"
                v-if="item.scheduleInfo.isRegular"
              >
                {{ formatEventDuration(item.allDates) }}
              </div>
              <div class="font-semibold text-gray-300 text-lg sf-pro-text" v-else>
                {{ dayjs(item.scheduleInfo.sessionDate).locale('ru').format('D MMMM') }}
              </div>
            </div>
            <div class="font-semibold text-primary-100 text-lg sf-pro-text" v-if="item.minPrice">
              от {{ item.minPrice }} ₽
            </div>
            <div
              class="font-semibold text-primary-100 text-lg sf-pro-text"
              v-else-if="item.tariff.type === 'free'"
            >
              Бесплатно
            </div>
            <div
              class="font-semibold text-primary-100 text-lg sf-pro-text"
              v-else-if="item.tariff.type === 'offline'"
            >
              Билеты в кассе
            </div>
          </template>
        </t-card>
      </div>
    </div>
    <div
      v-if="totalEvents > 4 && data.length < totalEvents"
      class="w-fit mx-auto font-sf-pro text-base cursor-pointer text-primary-100 pt-6 text-center"
      @click="showMore()"
    >
      <template v-if="!loader">
        <p>Показать еще</p>
      </template>
      <template v-else>
        <t-icon-preloader color="#2169D5"></t-icon-preloader>
      </template>
    </div>
  </div>
</template>

<script setup>
import dayjs from 'dayjs/esm/index.js'
import ru from 'dayjs/esm/locale/ru'
import { formatEventDuration } from '../helpers/formatEventDuration'

const props = defineProps({
  data: {
    type: Array,
    default: () => []
  },
  totalEvents: {
    type: Number,
    default: function () {
      return 0
    }
  },
  loader: {
    type: Boolean,
    default: () => false
  }
})

const emit = defineEmits('show-more')

function showMore() {
  emit('show-more')
}
</script>
